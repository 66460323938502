import {Pipe, PipeTransform} from '@angular/core';
import {FileTypeUtil} from '../classes/file-type.util';

@Pipe({name: 'getFilePathPipe'})
export class GetFilePathPipe implements PipeTransform {
    private static privatePipe = new GetFilePathPipe();

    public static getFilePath(extension: string, s3Key?: string): string {
        return GetFilePathPipe.privatePipe.transform(extension, s3Key);
    }

    public transform(extension: string, url?: string): string {
        const category = FileTypeUtil.getFileCategory(extension);
        // Check if an image can be displayed in the browser
        if (category === FileTypeUtil.CATEGORIES.IMAGE && FileTypeUtil.isWebSafe(url)) {
            return url;
        }

        if (FileTypeUtil.FILE_TYPE_CATEGORIES[category]) {
            return FileTypeUtil.FILE_TYPE_CATEGORIES[category].image;
        }

        return FileTypeUtil.FILE_TYPE_IMAGES.IMAGE;
    }
}
