import {autoserialize, inheritSerialization} from 'cerialize';
import {AppConstants} from '../../app.constants';
import {ENotificationType} from './notification.model';
import {IMonitoredItem} from '../../api/services/monitored-updates/monitored-item.interface';

export enum EJobStatus {
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    FAILED = 'FAILED',
}

export class MonitoredJobModel {
    static DOWNLOAD_JOBS = [
        AppConstants.JOBS.PACKAGE_GENERATION.name,
        AppConstants.JOBS.EXPORT_ASSETS_JOB.name,
        AppConstants.JOBS.EXPORT_IMAGE_JOB.name,
        AppConstants.JOBS.CAMPAIGN_PACKAGE_EXPORT_JOB.name,
        AppConstants.JOBS.CAMPAIGN_EXPORT_BRIEFING_JOB.name,
        AppConstants.JOBS.PRODUCT_EXPORT_JOB.name,
        AppConstants.JOBS.NOTE_EXPORT_JOB.name
    ];

    @autoserialize public _id: string;
    @autoserialize public type: string;

    constructor(_id: string, type: string) {
        this._id = _id;
        this.type = type;
    }

    get title(): string {
        return AppConstants.JOBS[this.type]?.title;
    }

    /**
     * Get notification type for the job
     *
     * @returns {ENotificationType} - Notification type
     */
    get notificationType () {
        return MonitoredJobModel.DOWNLOAD_JOBS.includes(this.type) ? ENotificationType.JOB_DOWNLOAD : ENotificationType.JOB;
    }
}

@inheritSerialization(MonitoredJobModel)
export class JobModel extends MonitoredJobModel implements IMonitoredItem {
    @autoserialize public result: string;
    @autoserialize public status: EJobStatus;

    constructor(_id: string, type: string, status?: EJobStatus, result?: string) {
        super(_id, type);

        this.status = status;
        this.result = result;
    }
}
