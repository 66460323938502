import {EAssetType} from '../models/api/asset.model';

export interface IFileType {
    extensions: string[];
    image: string;
}

export class FileTypeUtil {
    public static EXTENSIONS = {
        JPG: '.jpg',
        JPEG: '.jpeg',
        JPE: '.jpe',
        PSD: '.psd',
        PSB: '.psb',
        PNG: '.png',
        PDF: '.pdf',
        TIFF: '.tiff',
        TIF: '.tif',
        EPS: '.eps',
        EPT: '.ept',
        IDML: '.idml',
        INDL: '.indl',
        GIF: '.gif',
        AI: '.ai',
        ZIP: '.zip',
        RAR: '.rar',
        INDD: '.indd',
        INDT: '.indt',
        CSV: '.csv',
        XLIFF: '.xlf',
        XLS: '.xls',
        XLSX: '.xlsx',
        NUMBERS: '.numbers',
        PAGES: '.pages',
        SQL: '.sql',
        MOV: '.mov',
        QUICKTIME: '.qt',
        MPEG: '.mpeg',
        MP4: '.mp4',
        AVI: '.avi',
        WMV: '.wmv',
        SVG: '.svg',
        DOC: '.doc',
        DOCX: '.docx',
        TXT: '.txt',
        WEBP: '.webp',
        AEP: '.aep'
    };

    public static FILE_TYPE_IMAGES = {
        INDESIGN: 'assets/images/filetypes/indesign.svg',
        IMAGE: 'assets/images/filetypes/image.svg',
        PDF: 'assets/images/filetypes/pdf.svg',
        DATA: 'assets/images/filetypes/data.svg',
        VIDEO: 'assets/images/filetypes/video.svg',
        PHOTOSHOP: 'assets/images/filetypes/photoshop.svg',
        ZIP: 'assets/images/filetypes/zipfile.svg',
        VECTOR: 'assets/images/filetypes/vector.svg',
        DOCUMENT: 'assets/images/filetypes/documents.svg',
        MULTIPLE: 'assets/images/filetypes/multiple.svg',
        CSV: '/assets/images/filetypes/csv.svg',
        XLIFF: '/assets/images/filetypes/xliff.svg',
        PROCESSING: '/assets/images/filetypes/processing.svg',
        UNKNOWN: '/assets/images/filetypes/unknown.svg',
        AFTER_EFFECTS: '/assets/images/filetypes/after_effects.svg',
        SVG: 'assets/images/filetypes/svg.svg',
        FONT: 'assets/images/filetypes/font.svg'
    };

    public static CATEGORIES = {
        INDESIGN_LIBRARY: 'INDESIGN_LIBRARY',
        INDESIGN_TEMPLATE: 'INDESIGN_TEMPLATE',
        CSV: 'CSV',
        XLIFF: 'XLIFF',
        IMAGE: 'IMAGE',
        PDF: 'PDF',
        DATA: 'DATA',
        VIDEO: 'VIDEO',
        INDESIGN: 'INDESIGN',
        ILLUSTRATOR: 'ILLUSTRATOR',
        ARCHIVE: 'ARCHIVE',
        VECTOR: 'VECTOR',
        DOCUMENTS: 'DOCUMENTS',
        INDESIGN_GENERATION: 'INDESIGN_GENERATION',
        AFTER_EFFECTS: 'AFTER_EFFECTS',
        AFTER_EFFECTS_PROJECT_FILE: 'AFTER_EFFECTS_PROJECT_FILE',
        SVG_LIBRARY_FILE: 'SVG_LIBRARY_FILE',
        FONT_FILE: 'FONT_FILE'
    };

    public static FILE_TYPE_CATEGORIES = {
        IMAGE: {
            extensions: [
                FileTypeUtil.EXTENSIONS.PNG,
                FileTypeUtil.EXTENSIONS.JPG,
                FileTypeUtil.EXTENSIONS.JPEG,
                FileTypeUtil.EXTENSIONS.JPE,
                FileTypeUtil.EXTENSIONS.PSD,
                FileTypeUtil.EXTENSIONS.TIF,
                FileTypeUtil.EXTENSIONS.TIFF,
                FileTypeUtil.EXTENSIONS.EPS,
                FileTypeUtil.EXTENSIONS.EPT,
                FileTypeUtil.EXTENSIONS.GIF,
                FileTypeUtil.EXTENSIONS.AI,
                FileTypeUtil.EXTENSIONS.WEBP,
                FileTypeUtil.EXTENSIONS.SVG,
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.IMAGE
        },
        PDF: {
            extensions: [
                FileTypeUtil.EXTENSIONS.PDF,
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.PDF
        },
        DATA: {
            extensions: [
                FileTypeUtil.EXTENSIONS.CSV,
                FileTypeUtil.EXTENSIONS.XLS,
                FileTypeUtil.EXTENSIONS.XLSX,
                FileTypeUtil.EXTENSIONS.NUMBERS,
                FileTypeUtil.EXTENSIONS.SQL
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.DATA
        },
        VIDEO: {
            extensions: [
                FileTypeUtil.EXTENSIONS.MOV,
                FileTypeUtil.EXTENSIONS.QUICKTIME,
                FileTypeUtil.EXTENSIONS.MPEG,
                FileTypeUtil.EXTENSIONS.MP4,
                FileTypeUtil.EXTENSIONS.AVI,
                FileTypeUtil.EXTENSIONS.WMV
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.VIDEO
        },
        INDESIGN: {
            extensions: [
                FileTypeUtil.EXTENSIONS.IDML,
                FileTypeUtil.EXTENSIONS.INDL,
                FileTypeUtil.EXTENSIONS.INDT,
                FileTypeUtil.EXTENSIONS.INDD
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.INDESIGN
        },
        ILLUSTRATOR: {
            extensions: [
                FileTypeUtil.EXTENSIONS.AI
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.PHOTOSHOP
        },
        ARCHIVE: {
            extensions: [
                FileTypeUtil.EXTENSIONS.ZIP,
                FileTypeUtil.EXTENSIONS.RAR
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.ZIP
        },
        VECTOR: {
            extensions: [
                FileTypeUtil.EXTENSIONS.SVG
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.VECTOR
        },
        DOCUMENTS: {
            extensions: [
                FileTypeUtil.EXTENSIONS.DOC,
                FileTypeUtil.EXTENSIONS.DOCX,
                FileTypeUtil.EXTENSIONS.TXT,
                FileTypeUtil.EXTENSIONS.PAGES
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.DOCUMENT
        },
        INDESIGN_LIBRARY: {
            extensions: [
                FileTypeUtil.EXTENSIONS.INDL
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.INDESIGN
        },
        INDESIGN_TEMPLATE: {
            extensions: [
                FileTypeUtil.EXTENSIONS.INDT
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.INDESIGN
        },
        CSV: {
            extensions: [
                FileTypeUtil.EXTENSIONS.CSV
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.CSV
        },
        XLIFF: {
            extensions: [
                FileTypeUtil.EXTENSIONS.XLIFF
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.XLIFF
        },
        INDESIGN_GENERATION: {
            extensions: [
                FileTypeUtil.EXTENSIONS.PNG,
                FileTypeUtil.EXTENSIONS.JPG,
                FileTypeUtil.EXTENSIONS.JPEG,
                FileTypeUtil.EXTENSIONS.JPE,
                FileTypeUtil.EXTENSIONS.PSD,
                FileTypeUtil.EXTENSIONS.TIF,
                FileTypeUtil.EXTENSIONS.TIFF,
                FileTypeUtil.EXTENSIONS.EPS,
                FileTypeUtil.EXTENSIONS.EPT,
                FileTypeUtil.EXTENSIONS.GIF,
                FileTypeUtil.EXTENSIONS.AI,
                FileTypeUtil.EXTENSIONS.PDF,
                FileTypeUtil.EXTENSIONS.WEBP
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.IMAGE
        },
        AFTER_EFFECTS_PROJECT_FILE: {
            extensions: [
                FileTypeUtil.EXTENSIONS.ZIP
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.AFTER_EFFECTS
        },
        AFTER_EFFECTS: {
            extensions: [
                FileTypeUtil.EXTENSIONS.AEP
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.AFTER_EFFECTS
        },
        SVG_LIBRARY_FILE: {
            extensions: [
                FileTypeUtil.EXTENSIONS.ZIP
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.SVG
        },
        FONT_FILE: {
            extensions: [
                FileTypeUtil.EXTENSIONS.ZIP
            ],
            image: FileTypeUtil.FILE_TYPE_IMAGES.FONT
        }
    } as Record<string, IFileType>;

    public static DOWNLOAD_CATEGORIES = Object.keys(FileTypeUtil.CATEGORIES);

    public static isWebSafe(fileName: string): boolean {
        const extension = FileTypeUtil.getExtensionFromFileName(fileName);
        return [
            FileTypeUtil.EXTENSIONS.PNG,
            FileTypeUtil.EXTENSIONS.JPEG,
            FileTypeUtil.EXTENSIONS.JPG,
            FileTypeUtil.EXTENSIONS.GIF,
            FileTypeUtil.EXTENSIONS.WEBP
        ].includes(extension);
    }

    public static getFileCategory(fileName: string): string {
        const extension = FileTypeUtil.getExtensionFromFileName(fileName);

        if (extension) {
            for (const category in FileTypeUtil.FILE_TYPE_CATEGORIES) {
                if (FileTypeUtil.FILE_TYPE_CATEGORIES[category].extensions?.includes(extension)) {
                    return category;
                }
            }
        }
    }

    public static getFileCategoryByType(fileType: string): string {
        for (const category in FileTypeUtil.FILE_TYPE_CATEGORIES) {
            if (FileTypeUtil.FILE_TYPE_CATEGORIES[category].extensions?.includes(fileType)) {
                return category;
            }
        }
    }

    /**
     * Retrieves any extension information from a filename and lower cases it
     * @param name
     * @returns {string}
     */
    public static getExtensionFromFileName(name: string): string | null {
        if (name) {
            const matchExtensionRegExp: RegExp = new RegExp(/\.([^.\/?#]+)($|\?|#)/);
            const matches = matchExtensionRegExp.exec(name);
            if (matches?.length > 0) {
                return `.${matches[1].toLowerCase()}`;
            }
        }
        return null;
    }

    public static fileTypeMatchesExtensions(extension: string, fileType: string): boolean {
        let match = false;
        const typeArray = FileTypeUtil.FILE_TYPE_CATEGORIES[fileType];
        if (typeArray) {
            const indexFound = typeArray.extensions.indexOf(extension);
            match = indexFound > -1;
        }
        return match;
    }

    /**
     * Returns the filename without the extension or null if incorrect format
     * @param {string} name
     * @returns {string | null}
     */
    public static getFilenameWithoutExtension(name: string): string | null {
        if (name) {
            const filenameRegEx = new RegExp(/^(.*)\.[a-zA-Z0-9]*$/);
            const regexResult = filenameRegEx.exec(name);
            return regexResult.length > 0 ? regexResult[1] : null;
        }
        return null;
    }

    public static filterFiles(files: File[], fileTypes: string[]): File[] {
        return files.filter((file) => {
            const extension = FileTypeUtil.getExtensionFromFileName(file.name);
            return fileTypes.some((fileType) => FileTypeUtil.fileTypeMatchesExtensions(extension, fileType));
        });
    }

    public static getExtensionsFromFileCategories(categories: string[]): string[] {
        return [...new Set(categories.reduce((acc, category) =>
            acc.concat(...FileTypeUtil.FILE_TYPE_CATEGORIES[category].extensions), []))];
    }

    public static getAssetFileType(assetExtension: string): string {
        const val = FileTypeUtil.getFileCategoryByType(assetExtension);
        switch (val) {
            case 'PDF':
            case 'IMAGE':
                return EAssetType.IMAGE;
            case 'VIDEO':
                return EAssetType.VIDEO;
            default:
                return EAssetType.OTHER;
        }
    }
}
