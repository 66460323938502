import {autoserialize} from 'cerialize';
import {ICardMediaType} from '@relayter/rubber-duck';
import {FileTypeUtil} from '../../classes/file-type.util';

export class FileModel {
    @autoserialize public url: string;
    @autoserialize public s3Key: string;
    @autoserialize public size: number
    @autoserialize public extension: string;

    get media(): ICardMediaType {
        if (this.url){
            return {url: this.url, type: FileTypeUtil.getAssetFileType(this.extension)};
        }
        return null;
    }
}
