import {EMPTY, expand, Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {IResponseModel} from '../../models/interfaces/response-model.interface';
import {reduce} from 'rxjs/operators';
import {BaseApiRequestService} from './base-api-request.service';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {Injectable} from '@angular/core';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {ApiPatchRequest} from '../requests/api-patch.request';
import {ApiPostRequest} from '../requests/api-post.request';
import {ApiDeleteOneRequest} from '../requests/api-delete-one.request';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {ApiPutRequest} from '../requests/api-put.request';
import {JobModel} from '../../models/api/job.model';
import {ApiAutocompleteRequest} from '../requests/api-autocomplete.request';
import {ApiDeleteRequest} from '../requests/api-delete.request';
import {ITableItem} from '@relayter/rubber-duck';
import {ApiGetRequest} from '../requests/api-get.request';

@Injectable({
    providedIn: 'root'
})
export class ApiRequestService extends BaseApiRequestService {

    public get<T>(request: ApiGetRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public findOne<T extends IResponseModel>(request: ApiDetailsRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public find<T extends IResponseModel, Y extends Record<string, any>>(request: ApiPagedRequest): Observable<ARPagedResponseDataModel<T, Y>> {
        return new Observable((obs) => {
            this.handlePagedResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public findAll<T extends IResponseModel>(request: ApiPagedRequest): Observable<T[]> {
        // We're manipulating the cursor of the request, so first get a clone of the cursor
        request.cursor = request.cursor?.clone();

        return this.find(request).pipe(
            expand(response => {
                if (response.hasNext) {
                    // Update cursor with last item data
                    request.cursor.updateCursor(response.items[response.items.length - 1] as ITableItem);

                    return this.find(request);
                } else if (response.total && response.total > request.offset + request.limit) {
                    request.offset += request.limit;

                    return this.find(request);
                }

                return EMPTY;
            }),
            reduce((acc, val) => acc.concat(val.items), []),
        );
    }

    public autocomplete(request: ApiAutocompleteRequest): Observable<string[]> {
        return new Observable((obs) => {
            this.handleStringArrayResponse(request.options, obs);
        });
    }

    public create<T extends IResponseModel>(request: ApiPostRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public post<T extends IResponseModel>(request: ApiPostRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public patch<T extends IResponseModel>(request: ApiPatchRequest): Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public put<T extends IResponseModel>(request: ApiPutRequest):  Observable<T> {
        return new Observable((obs) => {
            this.handleDetailResponse<T>(request.options, obs, request.responseModel);
        });
    }

    public delete(request: ApiDeleteRequest):  Observable<boolean> {
        return new Observable((obs) => {
            this.handleNoErrorResponse(request.options, obs);
        });
    }

    public deleteOne(request: ApiDeleteOneRequest):  Observable<boolean> {
        return new Observable((obs) => {
            this.handleNoErrorResponse(request.options, obs);
        });
    }

    public postJob(request: ApiPostJobRequest): Observable<JobModel> {
        return new Observable((obs) => {
            this.handleDetailResponse<JobModel>(request.options, obs, JobModel);
        });
    }
}
