import {Injectable} from '@angular/core';
import {ERequestMethod} from '../app.enums';

@Injectable()
export class ApiConstants {
    public static readonly API_BASE_PATH: string = 'api';
    public static readonly API_SOCKET_ENDPOINT: string = '/relayter-socket';

    // Groups
    public static readonly API_GROUP_PRODUCTS: string = 'products';
    public static readonly API_GROUP_PRODUCT_IDS: string = 'product-ids';
    public static readonly API_GROUP_USERS: string = 'users';
    public static readonly API_GROUP_ROLES: string = 'roles';
    public static readonly API_GROUP_CONFIG: string = 'config';
    public static readonly API_GROUP_ASSETS: string = 'assets';
    public static readonly API_GROUP_CAMPAIGNS: string = 'campaigns';
    public static readonly API_GROUP_CAMPAIGN_ITEMS: string = 'campaign-items';
    public static readonly API_GROUP_DATA_FIELDS: string = 'data-fields';
    public static readonly API_GROUP_TEMPLATES: string = 'templates';
    public static readonly API_GROUP_PUBLICATIONS: string = 'publications';
    public static readonly API_GROUP_TEAM_ACCOUNTS: string = 'team-accounts';
    public static readonly API_GROUP_TEMPLATE_TYPES: string = 'template-types';
    public static readonly API_GROUP_DESIGN_LIBRARIES: string = 'design-libraries';
    public static readonly API_GROUP_SIGN_AWS_S3: string = 'sign-aws-s3';
    public static readonly API_GROUP_CHANGELOGS: string = 'changelogs';
    public static readonly API_GROUP_WORKFLOW_CONFIGURATIONS: string = 'workflow-configurations';
    public static readonly API_GROUP_STATIC_CONTENT_RULESETS: string = 'static-content-rulesets';
    public static readonly API_GROUP_MASTER_PAGES: string = 'master-pages';
    public static readonly API_GROUP_ACCOUNT_TYPES: string = 'account-types';
    public static readonly API_GROUP_EXCHANGE_AUTH0_ID_TOKEN: string = 'exchange-auth0-id-token';
    public static readonly API_GROUP_EXCHANGE_AUTH_TOKEN: string = 'exchange-auth-token';
    public static readonly API_GROUP_NOTIFICATIONS: string = 'notifications';
    public static readonly API_GROUP_PACKAGE_SETUPS: string = 'package-setups';
    public static readonly API_GROUP_PACKAGE_SETUPS_RULES: string = 'rules';
    public static readonly API_GROUP_WORKFLOW_AUTOMATIONS: string = 'workflow-automations';
    public static readonly API_GROUP_WORKFLOW_AUTOMATION_RULES: string = 'rules';
    public static readonly API_GROUP_PROPERTIES: string = 'properties';
    public static readonly API_GROUP_DATA_COLLECTION_VALUES: string = 'data-collection-values';
    public static readonly API_GROUP_CONNECTIONS: string = 'connections';
    public static readonly API_GROUP_OAUTH_CLIENTS: string = 'oauth-clients';
    public static readonly API_GROUP_OAUTH: string = 'oauth';
    public static readonly API_GROUP_TEAMS: string = 'teams';
    public static readonly API_GROUP_LAYOUTS: string = 'layouts';
    public static readonly API_GROUP_WEBHOOKS: string = 'webhooks';
    public static readonly API_GROUP_EVENTS: string = 'events';
    public static readonly API_GROUP_TRANSITION_ITEMS: string = 'transition-items';
    public static readonly API_GROUP_DEVICE_CODE: string = 'device-code';
    public static readonly API_GROUP_SESSIONS: string = 'sessions';
    public static readonly API_GROUP_PRODUCT_ASSET_EXPORT_SETUPS = 'product-asset-export-setups';
    public static readonly API_GROUP_SIGNED_URL: string = 'signed-url';
    public static readonly API_GROUP_USAGES = 'usages';
    public static readonly API_GROUP_AUDIT_TRAIL: string = 'audit-trail';
    public static readonly API_GROUP_EFFECTS_PROJECT_FILES: string = 'after-effects-project-files';
    public static readonly API_GROUP_FONTS: string = 'fonts';
    public static readonly API_METHOD_AUTHORIZE: string = 'authorize';
    public static readonly API_METHOD_LOGIN: string = 'login';
    public static readonly API_METHOD_LOGOUT: string = 'logout';
    public static readonly API_METHOD_REGISTER: string = 'register';
    public static readonly API_METHOD_INVITES: string = 'invites';
    public static readonly API_METHOD_FORGOT_PASSWORD: string = 'forgot-password';
    public static readonly API_METHOD_RESET_PASSWORD: string = 'reset-password';
    public static readonly API_METHOD_PERMISSIONS: string = 'permissions';
    public static readonly API_METHOD_USERS: string = 'users';
    public static readonly API_METHOD_WEB: string = 'web';
    public static readonly API_METHOD_ME: string = 'me';
    public static readonly API_METHOD_CHANGE_PASSWORD: string = 'change-password';
    public static readonly API_METHOD_PUBLICATIONS: string = 'publications';
    public static readonly API_METHOD_SIGNUP: string = 'sign-up';
    public static readonly API_METHOD_ITEMS: string = 'items';
    public static readonly API_METHOD_ASSET_ITEMS: string = 'asset-items';
    public static readonly API_METHOD_ITEM_GROUPS: string = 'item-groups';
    public static readonly API_METHOD_ITEMSCOUNT: string = 'itemscount';
    public static readonly API_METHOD_USAGE: string = 'usage';
    public static readonly API_METHOD_VALUES: string = 'values';
    public static readonly API_METHOD_CAMPAIGN_ITEMS: string = 'campaign-items';
    public static readonly API_METHOD_STICKY_NOTES: string = 'sticky-notes';
    public static readonly API_METHOD_LAYOUT_NOTES: string = 'layout-notes';
    public static readonly API_METHOD_LINK_ASSETS: string = 'link-assets';
    public static readonly API_METHOD_COMMENTS: string = 'comments';
    public static readonly API_METHOD_TRANSITIONS: string = 'transitions';
    public static readonly API_METHOD_ACTIONS: string = 'actions';
    public static readonly API_METHOD_FILTERS: string = 'filters';
    public static readonly API_METHOD_TRANSITION_ITEMS: string = 'transition-items';
    public static readonly API_METHOD_JOBS: string = 'jobs';
    public static readonly API_METHOD_ASSET_USAGE_IN_CAMPAIGNS: string = 'campaigns';
    public static readonly API_METHOD_TRANSFER_OWNERSHIP: string = 'transfer-ownership';
    public static readonly API_METHOD_EMAIL: string = 'email';
    public static readonly API_METHOD_ASSIGN_PUBLICATION_ITEM: string = 'assign-publication-item';
    public static readonly API_METHOD_PACKAGES: string = 'packages';
    public static readonly API_METHOD_STATISTICS: string = 'statistics';
    public static readonly API_METHOD_PACKAGE_INFO: string = 'package-info';
    public static readonly API_METHOD_SIGN_OFF_USERS: string = 'sign-off-users';
    public static readonly API_METHOD_ORDER: string = 'order';
    public static readonly API_METHOD_AUTOCOMPLETE: string = 'autocomplete';
    public static readonly API_METHOD_CALLBACK: string = 'callback';
    public static readonly API_METHOD_REFRESH_TOKEN: string = 'refresh-token';
    public static readonly API_METHOD_WEBHOOKS: string = 'webhooks';
    public static readonly API_METHOD_EVENTS: string = 'events';
    public static readonly API_METHOD_ENDPOINT: string = 'endpoints';
    public static readonly API_METHOD_IMAGE_SYNCHRONISATIONS: string = 'image-synchronisations';
    public static readonly API_METHOD_PRODUCT_SYNCHRONISATIONS: string = 'product-synchronisations';
    public static readonly API_METHOD_CAMPAIGN_ITEMS_CHANGES: string = 'campaign-items-changes';
    public static readonly API_METHOD_CONTENT: string = 'content';
    public static readonly API_METHOD_COMPONENTS: string = 'components';
    public static readonly API_METHOD_STEPS: string = 'steps';
    public static readonly API_METHOD_REFRESH: string = 'refresh';
    public static readonly API_METHOD_COMPOSITIONS: string = 'compositions';
    public static readonly API_METHOD_LAYERS: string = 'layers';
    public static readonly API_METHOD_ANIMATED_CONTENT_TEMPLATES: string = 'animated-content-templates';
    public static readonly API_METHOD_ANIMATED_CONTENT_RULESETS: string = 'animated-content-rulesets';
    public static readonly API_METHOD_ANIMATED_CONTENT_RULESETS_RULES: string = 'rules';
    public static readonly API_GROUP_CONTENT_RULES = 'content-rules';

    // Query params
    public static readonly API_QUERY_VALUE_DESC: string = 'desc';
    public static readonly API_QUERY_VALUE_ASC: string = 'asc';
    public static readonly REQUEST_METHODS = {
        POST: ERequestMethod.POST,
        GET: ERequestMethod.GET,
        PUT: ERequestMethod.PUT,
        PATCH: ERequestMethod.PATCH,
        DELETE: ERequestMethod.DELETE
    };
}
