import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {EJobStatus} from './job.model';
import {UserModel} from './user.model';
import {IMonitoredItem} from '../../api/services/monitored-updates/monitored-item.interface';

export enum ENotificationActionType {
    DOWNLOAD = 'DOWNLOAD',
    NAVIGATE = 'NAVIGATE',
    LINK = 'LINK'
}

export enum ENotificationStatus {
    READ = 'READ',
    UNREAD = 'UNREAD',
    ARCHIVED = 'ARCHIVED'
}

export enum ENotificationType {
    JOB = 'JOB',
    JOB_DOWNLOAD = 'JOB_DOWNLOAD',
    WORKFLOW_BETWEEN_STEPS_TRANSITION = 'WORKFLOW_BETWEEN_STEPS_TRANSITION',
    WORKFLOW_DELIVERY_LINKS = 'WORKFLOW_DELIVERY_LINKS',
    NOTE_UPDATE = 'NOTE_UPDATE',
    STICKY_COMMENT = 'STICKY_COMMENT',
    STICKY_COMMENT_MENTION = 'STICKY_COMMENT_MENTION',
    DEADLINE_NOTIFICATION_BEFORE = 'DEADLINE_NOTIFICATION_BEFORE', // reminder
    DEADLINE_NOTIFICATION_AFTER = 'DEADLINE_NOTIFICATION_AFTER', // warning
}

export class NotificationActionModel {
    @autoserialize title?: string;
    @autoserialize type: ENotificationActionType;
    @autoserialize url: string;
    @autoserialize text?: string;
}

class NotificationContentItem {
    @autoserialize public html: string;
    @autoserializeAs(NotificationActionModel) public action: NotificationActionModel;
}

@inheritSerialization(NotificationContentItem)
export class NotificationContentItemModel extends NotificationContentItem {
    @autoserializeAs(NotificationContentItem) public list: NotificationContentItem[];
}

export class NotificationDataModel {
    @autoserialize public jobStatus: EJobStatus;
}

export class NotificationModel implements IMonitoredItem {
    @autoserialize public _id: string;
    @autoserialize public title: string;
    @autoserialize public type: ENotificationType;
    @autoserializeAs(NotificationContentItemModel) public content: NotificationContentItemModel[];
    @autoserialize public status: ENotificationStatus;
    @autoserializeAs(NotificationDataModel) public data: NotificationDataModel;
    @autoserializeAs(UserModel) public sender: UserModel;
    @autoserializeAs(Date) public expires: Date;
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
}

export type NotificationTypeConfig = Record<ENotificationType, { title: string, description: string, destinations: ENotificationDestination[] }>;

export enum ENotificationDestination {
    EMAIL = 'EMAIL',
    NOTIFICATION_CENTRE = 'NOTIFICATION_CENTRE',
    TOASTER = 'TOASTER'
}

export const NotificationTypesConfig: NotificationTypeConfig =
    {
        [ENotificationType.JOB]: {
            title: 'Job update',
            description: 'Updates on Jobs you have created',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE, ENotificationDestination.TOASTER]
        },
        [ENotificationType.JOB_DOWNLOAD]: {
            title: 'Download Job update',
            description: 'Updates on Jobs you have created that include a download link',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE, ENotificationDestination.TOASTER]
        },
        [ENotificationType.WORKFLOW_BETWEEN_STEPS_TRANSITION]: {
            title: 'Publication item updates in a workflow step',
            description: 'When configured, notifications might be sent when publication items move between workflow steps',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE]
        },
        [ENotificationType.WORKFLOW_DELIVERY_LINKS]: {
            title: 'Publication item links',
            description: 'These are public links and are meant for non Relayter users',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE]
        },
        [ENotificationType.NOTE_UPDATE]: {
            title: 'Workflow note update',
            description: 'When configured, notifications might be sent on note creation or status updates',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE]
        },
        [ENotificationType.STICKY_COMMENT]: {
            title: 'Workflow note comments',
            description: 'When configured, notifications might be sent on note comments',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE]
        },
        [ENotificationType.STICKY_COMMENT_MENTION]: {
            title: 'Workflow note comment mention',
            description: 'When configured, notifications might be sent on mentioning in a note comment',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE]
        },
        [ENotificationType.DEADLINE_NOTIFICATION_BEFORE]: {
            title: 'Workflow schedule deadline (before)',
            description: 'When configured, notifications are sent based on the workflow schedule and publication deadline',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE]
        },
        [ENotificationType.DEADLINE_NOTIFICATION_AFTER]: {
            title: 'Workflow schedule deadline (after)',
            description: 'When configured, notifications are sent based on the workflow schedule and publication deadline',
            destinations: [ENotificationDestination.EMAIL, ENotificationDestination.NOTIFICATION_CENTRE]
        }
    };

export type NotificationDestinationConfig = Record<ENotificationDestination, string>;

export const NotificationDestinationsConfig: NotificationDestinationConfig =
    {
        [ENotificationDestination.EMAIL]: 'Email',
        [ENotificationDestination.NOTIFICATION_CENTRE]: 'Notification centre',
        [ENotificationDestination.TOASTER]: 'Toaster'
    };
