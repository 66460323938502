import {autoserialize} from 'cerialize';

export enum EGrantType {
    PASSWORD = 'password', // use username password to get an access token
    CLIENT_CREDENTIALS = 'client_credentials', // use client_id and client_secret to get an access token
    DEVICE_CODE = 'device_code', // use a device_code to get an access token
    AUTHORIZATION_CODE = 'authorization_code', // use an authorization_code to get an access token
    REFRESH_TOKEN = 'refresh_token' // Use a refresh_token to get an access token
}

export class OAuthClientModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public grant_types: EGrantType[];
    @autoserialize public client_id: string;
    @autoserialize public client_secret: string;
    @autoserialize public scopes: string[];
    @autoserialize public createdAt: Date;
    @autoserialize public updatedAt: Date;
}
